import React, { useEffect, useState } from "react";
import image from "../../assests/images/artist.jpg";

const ArtistDetailsection = (details) => {
  const [imgErr, setImgErr] = useState(false);

  return (
    <section className="relative sm:px-16 py-6 sm:pb-10 pb-6 bg-black">
      <div
        className="relative mb-6 w-full sm:h-60 h-80 sm:rounded-[20px] bg-cover bg-center border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100"
        style={{ backgroundImage: `url(https://t4.ftcdn.net/jpg/04/18/94/21/360_F_418942106_uQ87ggR7k5uY9e4nX6cD2lmfXFo7VtRG.jpg)` }}
      >
        <div className="absolute inset-0 flex sm:gap-x-8 gap-x-6 sm:px-10 px-4 bg-black bg-opacity-60 rounded-[20px]">
          <div className="!w-[20px] h-3/4 bg-yellow z-50 rounded-b-md"></div>
          <div className="flex flex-col justify-around py-6 gap-y-4 text-white">
            <div className="text-base text-gray-300">Artist</div>
            <div className="w-full">
              <div className="flex sm:flex-row flex-col	 gap-x-6">
                <div>
                  {imgErr == false ? (
                    <>
                      <img
                        src={details.artist.picturePath}
                        alt={details.artist.firstName}
                        onError={() => {
                          console.log("ERRROR");
                          setImgErr(true);
                        }}
                        className="h-40 w-40 rounded-[20px] mb-2 sm:mb-0 object-cover"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={image}
                        alt={details.artist.firstName}
                        onError={() => {
                          setImgErr(true);
                        }}
                        className="h-40 w-40 rounded-[20px] mb-2 sm:mb-0 object-cover"
                      />
                    </>
                  )}
                </div>
                <div className="flex-grow justify-end flex flex-col gap-y-4">
                  <div>
                    <div className="text-2xl sm:text-3xl font-bold mb-2">
                      {details.artist.nickName != undefined &&
                        details.artist.nickName != null &&
                        details.artist.nickName != ""
                        ? `${details.artist.firstName} ${(details.artist.lastName != undefined && details.artist.lastName != null && details.artist.lastName != 'null' && details.artist.lastName != '') ? details.artist.lastName : ''}`
                        : ''}
                    </div>
                    <div className="text-yellow text-base">{details.artist.nickName != undefined &&
                      details.artist.nickName != null &&
                      details.artist.nickName != ""
                      ? details.artist.nickName
                      : ''}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col justify-between sm:items-center gap-y-4 sm:mx-0 mx-4">
        <div>
          {details.artist.genreType && details.artist.genreType.length > 0 && <>
            <div className="text-gray-400 mb-2">Genres</div>
            <div className="flex flex-wrap gap-4">
              {details.artist.genreType.map((ele) => {
                return(<div className="sm:px-6 px-4 h-min py-1 rounded-[6px] border border-yellow/50 bg-yellow/20 text-yellow">{ele}</div>)
              })}
            </div>
          </>}
        </div>

        <div className="flex gap-4">
          <div className="text-white">
            <a href="#artist-story">Artist Story</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ArtistDetailsection;
