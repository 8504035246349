import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import Card from "@mui/material/Card";
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from "@mui/material/Typography";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import addDefault from "assests/images/no-picture-available.png";
import { GAEvent } from 'components/tracking';

export default function EventsLandingCardWidget({ event }) {
    const navigate = useNavigate();
    const [imageSrc, setImageSrc] = useState(event.picturePath);

    const handleImageError = () => {
        console.error("Error loading image:", event.picturePath);
        setImageSrc(addDefault);
    };

    const sendFeaturedEventData = () => {
        // navigate(`/event/${event._id}`, { state: event });
         window.location.href = `/event/${event._id}`
    }

    return (
        <div 
            style={{cursor:'pointer'}}
            className="w-full flex justify-center"
            data-umami-event-postname={event.eventName} 
            data-umami-event="Landing-Page-Featured-Event-Click"  
            onClick={(e)=>{
                sendFeaturedEventData();
                GAEvent({
                    category: 'Landing-Page-Featured-Event-Click',
                    action: 'Click',
                    label: event.eventName,
                  })
            }}
        >
            <Card className="w-full !bg-[#2A2A2A] !rounded-[20px] !shadow-2xl !drop-shadow-xl transition-transform transform hover:scale-105 overflow-hidden flex flex-col h-full">
                <CardMedia
                    component="img"
                    className="h-[220px] w-full object-cover"
                    image={imageSrc}
                    title={event.eventName}
                    onError={handleImageError}
                />
                <CardContent className="p-4 flex flex-col !justify-between gap-1.5 flex-grow">
                    <Typography variant="h5" component="div" className="text-white !font-bold text-lg">
                        {event.eventName}
                    </Typography>
                    <Typography variant="body2" className="flex items-center text-[#E6C100] text-opacity-80 text-sm my-auto">
                        <LocationOnIcon className="mr-1 text-xs"/> {event.location}
                    </Typography>
                    <div className="flex justify-between text-white text-xs ">
                        <Typography variant="body2">
                            {event.eventDate ? dayjs(event.eventDate).format("MMMM DD, YYYY") : ""}
                        </Typography>
                        <Typography variant="body2" className="text-right">
                            {event.eventStartTime ? dayjs(event.eventStartTime).format("hh:mm A") : ""} to {event.eventEndTime ? dayjs(event.eventEndTime).format("hh:mm A") : ""}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
