import React, { useEffect, useState } from "react";
import Logo from "../../assests/logo/Vybez_Logo.png";
import Dandiya from "../../assests/images/Dandiya.png";
import { apiUrl } from "config";
import { useNavigate } from "react-router-dom";

const LandingNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [specialEvents, setSpecialEvents] = useState([]);
  const [specialEventFetched, setSpecialEventsStatus] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  useEffect(() => {
    const fetchSpecialEventData = async () => {
      try {
        const response = await fetch(`${apiUrl}/special-events`);
        const data = await response.json();
        if (data.error == false) {
          setSpecialEvents(data.specialEvents);
          setSpecialEventsStatus(true)
        } else {
          console.log("Error fetching Special event data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSpecialEventData();
  }, []);
  return (
    <nav className="bg-black py-4 sm:px-8 px-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="">
          <a href="/">
            <img src={Logo} alt="Logo" className="h-24 w-auto" />
          </a>
        </div>
        <div className="hidden md:flex space-x-10 px-8">
          {specialEventFetched == true && specialEvents.length > 0 ?
            <>
              {specialEvents.map((sEvents, index) => {
                return (
                  <>
                    <div className="flex cursor-pointer justify-center !px-6 items-center !rounded-full !py-1 shadow-lg relative mx-auto -mt-3 border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100 gap-2"
                    onClick={()=>{
                      navigate(`/special-events/${sEvents.specialEventTagName}/${sEvents.specialEventName}`)
                    }}>
                      {/* Dandiya Sticks Icon */}
                      <div className="w-10 h-10 flex justify-center items-center">
                        <img src={sEvents.specialEventIcon} alt={sEvents.navBarMobileBtnName} />
                      </div>

                      {/* Dandiya Text */}
                      <div className="flex-grow flex items-center">
                        <p className="text-white text-base font-medium">{sEvents.specialEventName}</p>
                      </div>
                    </div></>
                )
              })}
            </>
            : ''}


          <a
            href="#aboutus"
            className="text-white text-base font-medium scroll-smooth hover:underline"
          >
            Explore Who We Are
          </a>
          <a
            href="/blog"
            className="text-white text-base font-medium hover:underline"
          >
            Blog
          </a>
          <a
            href="/login"
            className="text-[#E6C100] text-base !font-bold hover:underline"
          >
            Sign In
          </a>
        </div>
        <div className="md:hidden flex items-center gap-4 justify-center">

          {specialEventFetched == true && specialEvents.length > 0 ?
            <>
              {specialEvents.map((sEvents, index) => {
                return (
                  <>
                    <div className="flex cursor-pointer justify-center !px-3 -mb-2 items-center !rounded-full !py-1 shadow-lg relative -mt-3 border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100 gap-2 "
                    onClick={()=>{
                      navigate(`/special-events/${sEvents.specialEventTagName}/${sEvents.specialEventName}`)
                    }}
                    >
                      {/* Dandiya Sticks Icon */}
                      <div className="w-10 h-10 flex justify-center items-center">
                        <img src={sEvents.specialEventIcon} alt={sEvents.navBarMobileBtnName} />
                      </div>

                      {/* Dandiya Text */}
                      <div className="flex-grow flex items-center">
                        <p className="text-white text-sm font-medium">{sEvents.navBarMobileBtnName}</p>
                      </div>
                    </div>
                  </>
                )
              })}
            </>
            : ''}

          <a
            href="/login"
            className="text-[#E6C100] text-base !font-bold hover:underline"
          >
            Sign In
          </a>
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="p-4 mt-6">
            {specialEventFetched == true && specialEvents.length > 0 ?
              <>
                {specialEvents.map((sEvents, index) => {
                  return (
                    <>

                      <a
                        href=""
                        onClick={()=>{
                          navigate(`/special-events/${sEvents.specialEventTagName}/${sEvents.specialEventName}`)
                        }}
                        className="block cursor-pointer text-white text-base p-2 font-medium scroll-smooth hover:underline"
                      >
                        {sEvents.specialEventName}
                      </a></>
                  )
                })}
              </>
              : ''}

            <a
              href="#aboutus"
              className="block text-white text-base p-2 font-medium scroll-smooth hover:underline"
            >
              Explore Who We Are
            </a>
            <a
              href="/blog"
              className="block text-white text-base p-2 font-medium scroll-smooth hover:underline"
            >
              Blog
            </a>
            {/* <a
              href="/login"
              className="block text-[#E6C100] text-base !font-bold  p-2 font-medium scroll-smooth hover:underline"
            >
              Sign In
            </a> */}
          </div>
        </div>
      )}
    </nav>
  );
};

export default LandingNavbar;
