import { Box, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "scenes/landingPage/LandingNavbar";
import UserWidget from "scenes/widgets/UserWidget";
import EventWidget from "scenes/widgets/EventWidget";
import AdvertWidget from "scenes/widgets/AdvertWidget";
import FriendListWidget from "scenes/widgets/FriendListWidget";
import { apiUrl } from "../../config";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { seo } from "utilites/utlis";
import MoreEventSection from "./moreEventSection";

const FeaturedEventPage = () => {
  const location = useLocation();
  // const eventData = location.state;
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath } = useSelector((state) =>
    state.user ? state.user : {}
  );
  const [eventData, setEventData] = useState({});
  const token = useSelector((state) => state.token);
  const params = useParams();
  const [eventDataAvailable, setEventDataAvalibility] = useState(false);
  const getPostById = async () => {
    const response = await fetch(`${apiUrl}/posts/${params.eventId}/landing`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    console.log("data getPostById", data);
    updateInsights(data);
    setEventData(data);
    setEventDataAvalibility(true);
    seo({ title: data.eventName, metaDescription: data.eventName });
  };

  const updateInsights = async (data) => {
    const response = await fetch(`${apiUrl}/insights`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        insightType: "EVENT_VIEW",
        eventId: params.eventId,
        viewedByUserId: _id ? _id : "LANDING_FEATURED",
        profileId: data.userId,
      }),
    });
  };
  useEffect(() => {
    getPostById();
  }, []);
  return (
    <div>
      <Navbar />
      <div
        className={`w-full sm:p-16 ${
          isNonMobileScreens ? "flex justify-between" : "block"
        } gap-2`}
      >
        {eventDataAvailable === true ? (
          <div className={`${isNonMobileScreens ? "w-[70%]" : "mt-4"}`}>
            <EventWidget event={eventData} />
          </div>
        ) : (
          ""
        )}

        {isNonMobileScreens && (
          <div className="w-[28%]">
            <AdvertWidget />
            <div className="my-8" />
          </div>
        )}
      </div>
      <div className="flex justify-end">
        <div className="!w-[200px] !h-[20px]  sm:-mr-12 bg-yellow z-50 rounded-l-md"></div>
      </div>
      <MoreEventSection/>
    </div>
  );
};

export default FeaturedEventPage;
