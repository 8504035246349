import React from "react";
import LandingNavbar from "scenes/landingPage/LandingNavbar";
import BlogDetailscetion from "./blogDetail";
import BlogForm from "../createBlogPage/addBlog";
import MoreBlogSection from "./moreBlogSection";

const BlogsDetail = () => {
  return (
    <div className="bg-mainBg overflow-hidden">
      <LandingNavbar />
      <BlogDetailscetion/>
      <MoreBlogSection/>
      {/* <BlogForm/> */}
    </div>
  );
};

export default BlogsDetail;
