import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { apiUrl } from "config";
import dayjs from "dayjs";
import { GAEvent } from "components/tracking";
import { useNavigate } from "react-router-dom";


const NextArrow = ({ onClick }) => {
  return (
    <div className="absolute top-1/2 right-4 transform -translate-y-1/2 z-10 cursor-pointer">
      <ArrowForwardIosIcon
        style={{ color: "yellow", fontSize: "30px" }}
        onClick={onClick}
      />
    </div>
  );
};

const PrevArrow = ({ onClick }) => {
  return (
    <div className="absolute top-1/2 left-4 transform -translate-y-1/2 z-10 cursor-pointer">
      <ArrowBackIosIcon
        style={{ color: "yellow", fontSize: "30px" }}
        onClick={onClick}
      />
    </div>
  );
};

const SliderComponent = () => {
  const [sliderData, setFeaturedPosts] = useState([]);
  const navigate = useNavigate();

  const settings = {
    dots: true,
    autoplay: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => (
        <div className="w-2 h-2 bg-yellow-500 rounded-full mt-1"></div>
      ),
      appendDots: dots => (
        <div>
          <ul className="flex justify-center space-x-1">{dots}</ul>
        </div>
      ),
  };
  useEffect(() => {
    fetchEventData();
  }, []);

  const fetchEventData = async () => {
    try {
      const response = await fetch(`${apiUrl}/posts/all-featured-events/1/10`);
      const data = await response.json();
      setFeaturedPosts(data.posts);
      console.log(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const sendFeaturedEventData = (event) => {
    // navigate('/featured-event', { state: event });
    navigate(`/event/${event._id}`, { state: event });
}
  return (
    <section className="px-4 sm:px-16 py-6 mb-6">
      {sliderData.length > 0 ? <> 
        <Slider {...settings}>
        {sliderData.map((slide, index) => (
          <div
            key={index}
            className="relative w-full h-[437px] sm:w-[1160px] mx-auto rounded-[20px] overflow-hidden"
          >
            <div
              className="w-full h-full bg-cover bg-center"
              style={{ backgroundImage: `url(${(slide.bannerPath != undefined && slide.bannerPath!= null && slide.bannerPath!='null' && slide.bannerPath!='undefined') ? slide.bannerPath : slide.picturePath})` }}
            >
              <div className="w-full h-full flex flex-col gap-6 sm:p-16 p-12 items-center sm:items-stretch  justify-end bg-gradient-to-t from-black/80 to-black/30">
                <Typography variant="h1" className="text-4xl sm:text-left text-center text-[#D9D9D9] !font-extrabold">
                  {slide.eventName}
                </Typography>
                <Typography variant="h4" className="text-xl !font-bold">
                  {slide.location}
                </Typography>
                <Typography variant="body1" className="!text-lg text-justify">
                {slide.eventDate ? dayjs(slide.eventDate).format("MMMM DD, YYYY") : ""} | {slide.eventStartTime ? dayjs(slide.eventStartTime).format("hh:mm A") : ""} to {slide.eventEndTime ? dayjs(slide.eventEndTime).format("hh:mm A") : ""}
                </Typography>
                <div>
                  <Button variant="contained" color="primary" className="mt-4 hover:text-yellow border border-yellow"
                  data-umami-event-postname={slide.eventName}
                  data-umami-event="Landing-Page-Featured-lite-Event-explore-btn-click"
                  onClick={(e)=>{
                    sendFeaturedEventData(slide);
                    GAEvent({
                      category: 'Landing-Page-Featured-lite-Event-explore-btn-click',
                      action: 'Click',
                      label: slide.eventName,
                    })
                  }}>
                    Explore Now
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider></>: <></>}
    
    </section>
  );
};

export default SliderComponent;
