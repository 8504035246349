import { Typography, useTheme,useMediaQuery } from "@mui/material";
import FlexBetween from "components/FlexBetween";
import WidgetWrapper from "components/WidgetWrapper";
import { apiUrl } from "./../../config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import dayjs from "dayjs";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from "react-router-dom";
import addDefault from "assests/images/create-add.jpg"
import nopreview from "assests/images/no-picture-available.png"

const AdvertWidget = () => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;
  const [post, setPost] = useState({});
  const [postAvailable, setPostAvailability] = useState(false);
  const [imgErr, setImgErr] = useState(false);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const [viewMore, setViewMore] = useState(false);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");

  const getPost = async () => {
    const response = await fetch(`${apiUrl}/posts/featured`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await response.json();
    if (data.error == false) {
      if (data.post) {
        setPost(data.post)
        setPostAvailability(true)
      }
    }
  };

  useEffect(() => {
    getPost();
  }, []);

  const getClippedText = (text) => {
    
    if(isNonMobileScreens){
      if(text.length>180){
        let textRender = (
         <> {viewMore === false ?
          <>{text.substring(0, 100).split("\n").map(str => <p>{str}</p>) } < span style={{ cursor: 'pointer' }} onClick={()=>setViewMore(!viewMore)}><b> ...view more</b></span></>
                    : <>{text.split("\n").map(str => <p>{str}</p>)} <span style={{ cursor: 'pointer' }} onClick={()=>setViewMore(!viewMore)}><b> view less</b></span></> }</>)
        return textRender
      }else{
        return text
      }
      
    }else{
      if(text.length>80){
        let textRender = (
          <> {viewMore === false ?
           <>{text.substring(0, 80).split("\n").map(str => <p>{str}</p>) } < span style={{ cursor: 'pointer' }} onClick={()=>setViewMore(!viewMore)}><b> ...view more</b></span></>
                     : <>{text.split("\n").map(str => <p>{str}</p>)} <span style={{ cursor: 'pointer' }} onClick={()=>setViewMore(!viewMore)}><b> view less</b></span></> }</>)
         return textRender
      }else{
        return text
      }
    }
   
  }
  return (
    <WidgetWrapper style={{backgroundColor:'#3A3939'}}>
      {postAvailable == true ?
        <>
          <FlexBetween>
            <Typography color={dark} variant="h5" fontWeight="500">
              Sponsored
            </Typography>
            <Typography color={medium}>Create Ad</Typography>
          </FlexBetween>
          <span data-umami-event-advert={post.eventName} data-umami-event="Advert-Featured-Event-Click" style={{cursor:"pointer"}} onClick={() =>
            //  navigate(`/event/${post._id}`) 
             window.location.href = `/event/${post._id}`}>
          {imgErr == false ? ( <img
              width="100%"
              height="auto"
              alt="advert"
              onError={() => { setImgErr(true) }}
              src={`${post.picturePath}`}
              style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
            />) :  (<img
              width="100%"
              alt="post"
              style={{ borderRadius: "0.75rem", marginTop: "0.75rem", height: '30vh', objectFit: 'cover' }}
              src={`${nopreview}`}
            />)}
           
            <FlexBetween>
              <Typography color={main}>{post.eventName}</Typography>
              <Typography
                variant="body2"
                sx={{ color: medium, display: "flex", alignItems: "center" }}
              >
                <LocationOnIcon sx={{ fontSize: 16, marginRight: "4px" }} />
                {post.location}
              </Typography>
            </FlexBetween>
            <Typography color={medium} m="0.5rem 0">
              <Typography
                variant="body2"
                sx={{ color: main, display: "flex", alignItems: "center" }}
              >
                <CalendarMonthIcon sx={{ color: medium, fontSize: 16, marginRight: "4px" }} />
                {post.eventDate
                  ? dayjs(post.eventDate).format("DD MMM YYYY")
                  : ""} ({post.eventStartTime
                    ? dayjs(post.eventStartTime).format("hh:mm A")
                    : ""})
              </Typography>
              <br />
              {/* {post.description} */}
              {getClippedText(post.description)}
            </Typography>
          </span>
        </>
        :
        <>
          <FlexBetween>
            <Typography color={dark} variant="h5" fontWeight="500">
              Sponsored
            </Typography>
            <Typography color={medium}>Create Ad</Typography>
          </FlexBetween>
          <img
            width="100%"
            height="auto"
            alt="advert"
            src={`${addDefault}`}
            style={{ borderRadius: "0.75rem", margin: "0.75rem 0" }}
          />
          <FlexBetween>
            <Typography color={main}>ABC Events</Typography>
            <Typography
              variant="body2"
              sx={{ color: medium, display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon sx={{ fontSize: 16, marginRight: "4px" }} />
              Bengaluru
            </Typography>
          </FlexBetween>
          <Typography color={medium} m="0.5rem 0">
            Contact getfeatured@vybez.in to place you event add here.
          </Typography>
        </>
      }

    </WidgetWrapper>
  );
};

export default AdvertWidget;
