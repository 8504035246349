import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Stack, Pagination, useMediaQuery } from "@mui/material";
import EventsLandingCardWidget from "./EventsLandingCardWidget";
import { apiUrl } from "./../../config";
import "../../assests/css/swiper.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const SpecialEvent = (specialEventData,index) => {
  const navigate = useNavigate();
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [page, setPage] = useState(1);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");


  const getLimit = () => {
    const width = window.innerWidth;
    if (width <= 600) {
      return 1;
    } else {
      return 4;
    }
  };

  const limit = getLimit();

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(`${apiUrl}/posts/special-events/${page}/${limit}/${specialEventData.specialEventData.specialEventTagName}`);
        const data = await response.json();
        setFeaturedPosts(data.posts);
        setTotalPosts(data.totalPosts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEventData();
  }, [page, limit]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const getTotalPages = () => {
    return Math.ceil(totalPosts / limit);
  };

  const navigateToEvents = (specialEventTagName,specialEventName) => {
    navigate(`/special-events/${specialEventTagName}/${specialEventName}`);
    
  };

  return (
    <section className="px-4 sm:px-16 py-6 " style={{marginTop: (index== 0 ? '-40px': '')}}>
       <div className="pb-6 flex justify-between items-center">
       <Typography
        variant="h2"
        className={`!font-bold text-xl animate-slide-up pb-6 text-center md:text-left`}
      >
        {isNonMobileScreens == false ?
        <>
        {specialEventData?.specialEventData?.navBarMobileBtnName ? specialEventData?.specialEventData?.navBarMobileBtnName : 'Special Events'}
        </>: <>
          {specialEventData?.specialEventData?.specialEventName ? specialEventData?.specialEventData?.specialEventName : 'Special Events'}
          </>}
      </Typography>
        <div
          onClick={() => {
            navigateToEvents(specialEventData?.specialEventData?.specialEventTagName,specialEventData?.specialEventData?.specialEventName);
          }}
          className="text-yellow flex items-center gap-x-2 cursor-pointer"
        >
          <div className="text-base">View All</div>
          <ArrowForwardIosIcon />
        </div>
      </div>
      
      {featuredPosts.length === 0 ? (
        <Typography variant="body2" className="pb-2 text-neutral-600">
          No Events Found
        </Typography>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {featuredPosts.map((event) => (
              <EventsLandingCardWidget key={event._id} event={event} />
            ))}
          </div>
          {getTotalPages() >= 2 && (
            <Stack spacing={2} className="mt-8 flex items-center">
              <Pagination
                count={getTotalPages()}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
                page={page}
              />
            </Stack>
          )}
        </>
      )}
    </section>
  );
};

export default SpecialEvent;
