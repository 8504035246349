import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Stack, Pagination } from "@mui/material";
import { apiUrl } from "./../../config";
import "../../assests/css/swiper.css";
import EventsLandingCardWidget from "scenes/landingPage/EventsLandingCardWidget";

const MoreEventSection = () => {
  const navigate = useNavigate();
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [page, setPage] = useState(1);

  const getLimit = () => {
    const width = window.innerWidth;
    if (width <= 600) {
      return 1;
    } else {
      return 4;
    }
  };

  const limit = getLimit();

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(
          `${apiUrl}/posts/all-featured-events/${page}/${limit}`
        );
        const data = await response.json();
        setFeaturedPosts(data.posts);
        setTotalPosts(data.totalPosts);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchEventData();
  }, [page, limit]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const getTotalPages = () => {
    return Math.ceil(totalPosts / limit);
  };
  return (
    <section className="px-4 sm:px-10 py-6 mb-20">
      <div
        className="sm:p-8 rounded-lg relative"
        style={{
          overflow: "hidden",
        }}
      >
        <div className="pb-6 flex justify-between items-center">
          <Typography
            variant="h2"
            className={`!font-bold text-xl animate-slide-up text-white  text-center md:text-left`}
          >
            More Events
          </Typography>
          {/* <div
            onClick={() => {
              navigateToArtists();
            }}
            className="text-yellow cursor-pointer flex items-center gap-x-2 z-50"
          >
            <div className="text-base">View All</div>
            <ArrowForwardIosIcon />
          </div> */}
        </div>
        {featuredPosts.length === 0 ? (
          <Typography variant="body2" className="pb-2 text-neutral-600">
            No Events Found
          </Typography>
        ) : (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              {featuredPosts.map((event) => (
                <EventsLandingCardWidget key={event._id} event={event} />
              ))}
            </div>
            {getTotalPages() >= 2 && (
              <Stack spacing={2} className="mt-8 flex items-center">
                <Pagination
                  count={getTotalPages()}
                  variant="outlined"
                  shape="rounded"
                  onChange={handleChange}
                  page={page}
                />
              </Stack>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default MoreEventSection;
