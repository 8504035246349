import React from "react";
import LandingNavbar from "scenes/landingPage/LandingNavbar";
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";
import SpecialEventsSection from "./specialEventSection";

const SpecialEventList = () => {
  const isAuth = Boolean(useSelector((state) => state.token));
  
  return (
    <div className="bg-mainBg overflow-hidden">
      {isAuth ? <Navbar /> : <LandingNavbar />}
      
      <SpecialEventsSection />
    </div>
  );
};

export default SpecialEventList;
