import React from "react";
import LandingNavbar from "scenes/landingPage/LandingNavbar";
import BlogForm from "./addBlog";

const CreateBlog = () => {
  return (
    <div className="bg-mainBg overflow-hidden">
      <LandingNavbar />
      <BlogForm/>
    </div>
  );
};

export default CreateBlog;
