import React from 'react';
import LandingNavbar from 'scenes/landingPage/LandingNavbar';
import ErrorSection from './errorSection';
import Footer from 'scenes/commons/Footer';
import { useSelector } from "react-redux";
import Navbar from "scenes/navbar";

const ErrorPage = () => {
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <div className="bg-mainBg">
        {isAuth ? <Navbar /> : <LandingNavbar />}
        <ErrorSection/>
        <Footer/>
    </div>
  );
};

export default ErrorPage;
