import React from "react";

const VenueDetailsection = (details) => {
  // console.log("VenueDetailsection",details.venue)
  return (
    <section className="relative sm:px-16 py-6 sm:pb-10 pb-6 bg-black">
      <div
        className="relative mb-3 w-full sm:h-60 h-80 sm:rounded-[20px] bg-cover bg-center border-gradient from-stop-0 via-stop-28 via-stop-62 to-stop-100"
        style={{ backgroundImage: `url(${details.venue.bannerPath})` }}
      >
        <div className="absolute inset-0 flex sm:gap-x-8 gap-x-6 sm:px-10 px-4 bg-black bg-opacity-50 rounded-[20px]">
          <div className="!w-[20px] h-3/4 bg-yellow z-50 rounded-b-md"></div>
          <div className="flex flex-col justify-around py-6 gap-y-4 text-white">
            <div className="text-base text-gray-300">Venues</div>
            <div className="w-full">
              <div className="flex sm:flex-row flex-col	 gap-x-6">
                <div>
                  <img
                    src={details.venue.picturePath}
                    alt={details.venue.venuName}
                    className="h-40 w-40 rounded-[20px] mb-2 sm:mb-0"
                  />
                </div>
                <div className="flex-grow justify-end flex flex-col gap-y-4">
                  <div>
                    <div className="text-2xl sm:text-3xl font-bold mb-2">
                      {details.venue.venuName}
                    </div>
                    <div className="flex gap-x-4">
                      <div className="text-yellow text-base">{details.venue.location}</div>
                      <div className="border border-l-white"></div>
                      <div className="text-yellow text-base">12:00 PM - 1:00 AM</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex sm:flex-row flex-col justify-between sm:items-center gap-y-4 sm:mx-0 mx-4">
        <div>
          {details.venue.genreType && details.venue.genreType.length > 0 && <>
            <div className="text-gray-400 mb-2">Vibe</div>
            <div className="flex flex-wrap gap-4">
              {details.venue.genreType.map((ele) => {
                return(<div className="sm:px-6 px-4 h-min py-1 rounded-[6px] border border-yellow/50 bg-yellow/20 text-yellow">{ele}</div>)
              })}
            </div>
          </> }
        </div>
        
        <div className="flex gap-4">
          <div className="text-white">
            <a href="#about-location">Venue Overview</a>
          </div>
          <div className="border border-yellow"></div>
          <div className="text-white">
            <a href="#about-location">Location</a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VenueDetailsection;
