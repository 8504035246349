import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Logo from "../../assests/logo/Vybez_Logo.png";
import backgroundLogin from "../../assests/images/login-background.png";
import SignUpFlow from "./signUpFlow";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import InstagramLoginPage from "./instagramLogin";
import './login.css';
import { useState } from "react";

const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate()
  const [dispTxt, setdispTxt] = useState('Move Counts!');
  const isNonMobile = useMediaQuery("(min-width:600px)");

  useEffect(() => {
    let token = localStorage.getItem("token");
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user)
    }
    if (token && token != undefined && token != null) {
      navigate('/home')
    }
    let interval = setInterval(() => {
      if (dispTxt == 'Move Counts!') {
        setdispTxt('Groove Unites!')
        clearInterval(interval)
      } else if (dispTxt == 'Groove Unites!') {
        setdispTxt('Beat Resonates!')
        clearInterval(interval)
      } else if (dispTxt == 'Beat Resonates!') {
        setdispTxt('Move Counts!')
        clearInterval(interval)
      }
    }, 2000)
  })
  return (
    <>
      {isNonMobile ?
        <div style={{ width: '100%', height: '100vh', backgroundSize: 'contain', backgroundImage: `url(${backgroundLogin})`, display: 'flex' }}>
          <div style={{
            width: '100%',
            position: "fixed",
            bottom: "5%",
            left: "5%"
          }}>
            <Typography variant='h4' gridColumn="span 6" color='white'>Dance the Night Away:</Typography>
            <Typography variant='h4' gridColumn="span 6" color='white'>Where Every <span style={{ color: '#E6C100', transition: 'opacity 1s' }}>{dispTxt}</span></Typography>
          </div>
          <div style={{
            "rotate": "-60deg",
            "position": "fixed",
            "width": "150%",
            "height": "100%",
            "top": "-90%",
            "left": "37%",
            "borderRadius": "200px",
            "background": "#000000d9"
          }}>

          </div>
          <div style={{
            "width": "80%",
            "textAlign": "end",
            "zIndex": "1",
            "position": "relative",
            "left": "43%"
          }}>
            <div style={{
              textAlign: 'center',
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: 'relative',
              alignItems: 'center',
              height: '100%'
            }}>
              <div style={{display:'contents'}}>
                <img
                  style={{
                    marginTop: '2.5rem',
                    // position: "fixed",
                    // top: "1%",
                    // left: "1%",
                    mixBlendMode: "difference",
                    width: isNonMobileScreens ? "12%" : "30%",
                    height: isNonMobileScreens ? "auto" : "20%",
                    objectFit: 'contain'
                  }}
                  src={Logo}
                  alt={"LOGO"}
                />
              </div>

              <div style={{
                "height": "100%",
                display: "flex",
                alignItems: "center", textAlign: 'left'
              }}>
                {/* <SignUpFlow /> */}
                <InstagramLoginPage></InstagramLoginPage>
              </div>
            </div>
            {/* <Box>
         <Box sx={{ width: "100%", marginTop: '1%', marginLeft: '1%' }}>

         </Box>

         <Box
           style={{
             display: "flex",
             justifyContent: "center",
             alignItems: "baseline",
             height: "100vh",
           }}
         >
           <Box width={isNonMobileScreens ? "60%" : "93%"}>
             <SignUpFlow />
           </Box>
         </Box>
       </Box> */}
          </div>

        </div>
        :
        <div style={{ width: '100%', height: '80vh', backgroundSize: 'cover', backgroundImage: `url(${backgroundLogin})`, display: 'flex' }}>



          <div style={{
            "width": "100%",
            "textAlign": "center",
            "zIndex": "1",
            "position": "relative",
            "background": "#000000d9"
          }}>
            <div style={{
              textAlign: 'center',
              display: "flex",
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: 'relative',
              alignItems: 'center',
              height: '100%'
            }}>
              <div style={{display:'contents'}}>
                <img
                  style={{
                    marginTop: '2.5rem',
                    // position: "fixed",
                    // top: "1%",
                    // left: "1%",
                    mixBlendMode: "difference",
                    width: isNonMobileScreens ? "12%" : "30%",
                    height: isNonMobileScreens ? "auto" : "auto",
                    objectFit: 'contain'
                  }}
                  src={Logo}
                  alt={"LOGO"}
                />
              </div>

              <div style={{
                "height": "100%",
                display: "flex",
                alignItems: "center", textAlign: 'left'
              }}>
                {/* <SignUpFlow /> */}
                <InstagramLoginPage></InstagramLoginPage>
              </div>
              <div style={{
                width: '100%',
                textAlign: 'center',
                zIndex: '2'
              }}>
                <Typography variant='h4' gridColumn="span 6" color='white'>Dance the Night Away:</Typography>
                <Typography variant='h4' gridColumn="span 6" color='white'>Where Every <span style={{ color: '#E6C100', transition: 'opacity 1s' }}>{dispTxt}</span></Typography>
              </div>
            </div>

          </div>

        </div>}

    </>


  );
};

export default LoginPage;
