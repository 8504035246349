import React from 'react';
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import HomePage from "scenes/homePage";
import LoginPage from "scenes/loginPage";
import ProfilePage from "scenes/profilePage";
import EventPage from "scenes/eventpage";
import EventTicketPage from "scenes/eventTicketPage";
import CheckoutPage from "scenes/checkoutPage";
import BookingHistoryPage from "scenes/bookingHistoryPage";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
// import CreateEventPage from "scenes/createEventPage";
import CreateEvent from "scenes/createEventPage";
import Explore from "scenes/explore";
import ViewTicketPage from "scenes/viewTicketPage";
import VendorVerification from "scenes/vendor-verification";
import ArtistVerification from "scenes/artist-verification";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from "scenes/dashboard";
import MyEvents from "scenes/my-events";
import LandingPage from "scenes/landingPage";
import PrivacyPolicyPage from "scenes/privacyPolicyPage";
import RefundPolicyPage from "scenes/refundPolicyPage";
import TermsAndConditionPage from "scenes/termsAndConditionPage";
import AboutUsPage from "scenes/aboutUsPage";
import MyEventsList from "scenes/my-events/event-list";
import ContactPage from "scenes/contactPage";
import FeaturedEventPage from "scenes/featuredEventPage";
import AuthFeaturedEventPage from "scenes/authFeaturedEventPage";
import ReactGA from 'react-ga4';
import { googleAnalyticsTrackingId } from "config";
import Blogs from 'scenes/blogPage';
import BlogsDetail from 'scenes/blogDetailsPage';
import ArtistDetail from 'scenes/artistDetailPage';
import VenueDetail from 'scenes/venueDetailPage';
import VenueList from 'scenes/venueListPage';
import ArtistList from 'scenes/artistListPage';
import ErrorPage from 'scenes/404ErrorPage';
import CreateBlog from 'scenes/createBlogPage';
import SpecialEventList from 'scenes/specialEventListPage';



function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));
  const dispatch = useDispatch();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  ReactGA.initialize(googleAnalyticsTrackingId);

  useEffect(() => {
    let token = localStorage.getItem("token");
    let user = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user)
    }
    if (token && token != undefined && token != null) {
      dispatch(
        setLogin({
          user: user,
          token: token,
        })
      );
    }
  }, [(useSelector((state) => state.token))])
  const { palette, typography } = useTheme();


  

  return (
    <div className="app">
      <BrowserRouter basename="/">
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer theme="dark" />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/home"
              element={isAuth ? <HomePage /> : <Navigate to="/"/>}
            />
            <Route
              path="/blog"
              element={<Blogs />}
            />
            <Route
              path="/create-blog"
              element={<CreateBlog />}
            />
            <Route
              path="/blog-detail/:blogId"
              element={<BlogsDetail />}
            />
            <Route
              path="/artists"
              element={<ArtistList/>}
            />
            <Route
              path="/artist-detail/:profileSlug"
              element={<ArtistDetail />}
            />
            <Route
              path="/venues"
              element={<VenueList />}
            />
            <Route
              path="/venue-detail/:venuSlug"
              element={<VenueDetail />}
            />
            <Route
              path="/event/:eventId"
              element={isAuth ? <EventPage /> : <FeaturedEventPage />}
            />
            <Route
              path="/ticket/:eventId"
              element={isAuth ? <EventTicketPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/checkout/:transactionId"
              element={isAuth ? <CheckoutPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/bookinghistory"
              element={isAuth ? <BookingHistoryPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/viewticket"
              element={isAuth ? <ViewTicketPage /> : <Navigate to="/login" />}
            />
            <Route
              path="/create-event/:eventId"
              element={isAuth ? <CreateEvent theme={{ palette: palette, typography: typography, isNonMobileScreens: isNonMobileScreens }} /> : <Navigate to="/login" />}
            />
            <Route
              path="/profile/:userId"
              element={isAuth ? <ProfilePage /> : <Navigate to="/login" />}
            />
            <Route
              path="/explore"
              element={isAuth ? <Explore /> : <Navigate to="/login" />}
            />
            <Route
              path="/verify-vendor"
              element={isAuth ? <VendorVerification /> : <Navigate to="/login" />}
            />
            <Route
              path="/verify-artist"
              element={isAuth ? <ArtistVerification /> : <Navigate to="/login" />}
            />
            <Route
              path="/dashboard"
              element={isAuth ? <Dashboard /> : <Navigate to="/login" />}
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicyPage />}
            />
            <Route
              path="/refund-policy"
              element={<RefundPolicyPage />}
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionPage />}
            />
            <Route
              path="/about-us"
              element={<AboutUsPage />}
            />
             <Route
             path="/my-events"
             element={isAuth ? <MyEvents /> : <Navigate to="/login" />} 
             />
            <Route
              path="/contact"
              element={<ContactPage />}
            />
            <Route
              path="/featured-event"
              element={isAuth ? <AuthFeaturedEventPage /> : <FeaturedEventPage />} 
            />
            <Route
              path="/bookings/:eventId"
              element={isAuth ? <MyEventsList /> : <Navigate to="/login" />} />

            <Route
              path="/special-events/:specialEventTagName/:specialEventName"
              element={<SpecialEventList />}
            />

            <Route
              path="*"
              element={<Navigate to="/" />} />
              
              <Route path='/404'
              element={<ErrorPage/>}/>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
