import React from "react";
import LandingNavbar from "./LandingNavbar";
import Footer from "./../commons/Footer";
import AboutUs from "../commons/AboutUs";
import FeaturedEvents from "./FeaturedEvents";
import HeroSection from "./HeroSection";
import VenueCarousel from "./Venues";
import AddsSliderComponent from "./AddsSlider";
import ArtistsSection from "./ArtistSection";
import BlogSection from "./BlogSection";
import BgImage from "../../assests/images/bg-body.png";
import AboutUsLandingPage from "./AboutUs";
import SpecialEventMain from "./SpecialEventMain";


const LandingPage = () => {
  return (
    <div className="bg-mainBg overflow-hidden">
      <LandingNavbar />
      <HeroSection />
      <SpecialEventMain />
      <FeaturedEvents />
      <VenueCarousel />
      <div style={{ backgroundImage: `url(${BgImage})` }}>
        <AddsSliderComponent />
        <ArtistsSection />
        <BlogSection />
      </div>
	  <AboutUsLandingPage/>
      <Footer />
    </div>
  );
};

export default LandingPage;
