
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Stack, Pagination } from "@mui/material";
import EventsLandingCardWidget from "./EventsLandingCardWidget";
import { apiUrl } from "./../../config";
import "../../assests/css/swiper.css";
import SpecialEvent from "./specialEvent";

const SpecialEventMain = () => {
    const [specialEvents, setSpecialEvents] = useState([]);
    const [specialEventFetched, setSpecialEventsStatus] = useState(false);


    useEffect(() => {
        const fetchSpecialEventData = async () => {
            try {
                const response = await fetch(`${apiUrl}/special-events`);
                const data = await response.json();
                if (data.error == false) {
                    setSpecialEvents(data.specialEvents);
                    setSpecialEventsStatus(true)
                } else {
                    console.log("Error fetching Special event data:", data.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchSpecialEventData();
    }, []);

    return (
        <>
         { specialEventFetched == true && specialEvents.length > 0 ?
            <>
                {specialEvents.map((sEvents,index) => {
                    return (<SpecialEvent specialEventData={sEvents} index={index}></SpecialEvent>)
                })}
            </>
            : ''}
        </>

    );
};

export default SpecialEventMain;
