import React from "react";
// import { useNavigate } from "react-router-dom";

const VenueAboutSection = (details) => {
  // const navigate = useNavigate();
  // const getDirections = () => {
  //   navigate(`#`);
  // };
  return (
    <section className="px-4 sm:px-24 py-10" id="about-location" >
      <div className="flex flex-col sm:flex-row">
        <div className="sm:w-3/5 px-4">
          <div className="text-xl text-yellow mb-4">Get to Know Our Venue</div>
          <div>
            {(details.venue.introduction !== undefined && details.venue.introduction!==null && details.venue.introduction!=='null' && details.venue.introduction!=='' && details.venue.introduction!=='undefined') ? 
        details.venue.introduction : 'Information not available'}
          </div>
        </div>
        <div className="border-r border-gray-400"></div>
        <div className="w-full sm:w-2/5 px-4 text-white mt-6 sm:mt-0">
          <div className="text-xl mb-4 text-yellow">Location</div>
          <div>
            {details.venue.address}
          </div>
          {/* <div
            onClick={() => {
              getDirections();
            }}
            className="cursor-pointer p-3 border my-4 w-fit rounded"
          >
            Get Directions
          </div> */}
        </div>
      </div>
      <div className="flex justify-end">
        <div className="!w-[200px] !h-[20px] -mr-10 sm:-mr-32 mt-4 bg-yellow z-50 rounded-l-md"></div>
      </div>
    </section>
  );
};

export default VenueAboutSection;
