import React, { useState, useEffect } from "react";
import CkTextEditor from "scenes/ckEditor";

const BlogForm = ({ blogData = {}, onFormSubmit }) => {
  const [title, setTitle] = useState(blogData.title || "");
  const [content, setContent] = useState(blogData.content || ""); 
  const [mainImage, setMainImage] = useState(blogData.mainImage || "");
  const [isDragging, setIsDragging] = useState(false);

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  const todayDate = formatDate(new Date());

  useEffect(() => {
    console.log("Blog Data:", blogData);
  }, [blogData]);

  const handleImageClick = (e) => {
    e.preventDefault();
    document.getElementById("fileInput").click();
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setMainImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      title,
      date: todayDate,
      content,
      mainImage,
    };

    console.log("Form Data:", formData);

    if (onFormSubmit) {
      onFormSubmit(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="px-4 sm:px-24 py-6">
      <div className="flex sm:gap-x-6 gap-x-4 px-2">
        <div className="w-[20px] bg-yellow -mt-4 sm:-mt-6 rounded-b-md"></div>
        <div className="flex-grow flex flex-col gap-y-4">
          <div className="text-base text-gray-500">Blog</div>
          <div>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="text-lg bg-[#2A2A2A] sm:text-3xl font-bold p-2 mb-2 w-full"
              placeholder="Title"
            />
            <div className="text-yellow">{todayDate}</div>
          </div>
        </div>
      </div>
      <div
        className={`mt-8 flex items-center justify-center relative border-2 border-dashed ${
          isDragging ? "border-yellow-500" : "border-gray-400"
        }`}
        style={{
          width: "80%",
          height: "60vh",
          borderRadius: "20px",
        }}
        onClick={handleImageClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        {mainImage ? (
          <img
            src={mainImage}
            alt="Blog Main"
            className="w-full h-full rounded-[20px] object-cover"
          />
        ) : (
          <div className="text-center text-gray-500">
            Drag and drop an image here, or click to select a file
            <input
              type="file"
              id="fileInput"
              onChange={handleImageUpload}
              className="absolute inset-0 opacity-0 cursor-pointer"
              accept="image/*"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
        )}
      </div>
      <div className="py-10">
        <CkTextEditor
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
          }}
          data={content}
        />
      </div>
      <div className="flex justify-end">
        <button
          type="submit"
          className="mt-2 px-6 py-2 bg-yellow text-black rounded"
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default BlogForm;
